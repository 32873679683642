import UserTask from "@/models/UserTaskModel";
import Vue from "vue";
import UserTaskController from "@/http/controllers/UserTaskController";
import UserTaskState from "@/models/UserTaskState";

export default abstract class UserTasksBase extends Vue {
    constructor() {
        super();
        this.controller = new UserTaskController();
    }
    private controller: UserTaskController;

    userTasks: UserTask[] = [];

    getUserTasks(states: UserTaskState[]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            try {
                this.controller
                    .list(states)
                    .then(data => {
                        this.userTasks = data;
                        resolve();
                    }).catch(error => reject(error));
            } catch (error) {
                reject(error);
            }
        });
    }
}