import { auth } from "@/utils/AuthService";
import { AxiosBuilder } from "@gis/common/dist/AxiosSetup";
import { AxiosInstance } from "axios";

export default abstract class ControllerBase {
    axios: AxiosInstance;
    withLoader = true;
    constructor(withLoader: boolean | null = null) {
        if(withLoader !== null) {
            this.withLoader = withLoader;
        }
        this.axios = new AxiosBuilder(process.env.VUE_APP_API_BASE_URL as string).withAccessToken(auth().getAccessToken()).build();
    }
}