import UserTaskModel from "@/models/UserTaskModel";
import UserTaskState from "@/models/UserTaskState";
import ControllerBase from "./ControllerBase";
import UserTasksEndpoints from "../endpoints/UserTasksEndpoints";
import QueryString from "qs";

export default class UserTaskController extends ControllerBase {
    list(states: UserTaskState[], userRestricted = true): Promise<UserTaskModel[]> {
        return new Promise<UserTaskModel[]>((resolve, reject) => {
            this.axios({
                url: UserTasksEndpoints.list.url,
                method: UserTasksEndpoints.list.method,
                params: {
                    states: states,
                    userRestricted: userRestricted
                },
                paramsSerializer: params => {
                    return QueryString.stringify(params, { arrayFormat: "repeat" })
                }
            })
                .then(response => {
                    const data = response.data as UserTaskModel[];
                    const models = data;
                    resolve(models);
                }).catch(() => reject());
        });
    }
}