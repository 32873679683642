
import UserTaskModel from "@/models/UserTaskModel";
import { Vue, Component, Prop } from "vue-property-decorator";
import Cookies from 'js-cookie';


@Component({ name: "usertask-record" })
export default class UserTaskRecord extends Vue {
    @Prop(Object)
    readonly userTask!: UserTaskModel;
    @Prop(Boolean)
    readonly readonly!: boolean;

    gotoTask(): void {
        if (this.readonly) {
            return;
        }
        const deviceTag = Cookies.get('DeviceTag');
        var url = this.userTask.url.replace('{externalId}', this.userTask.externalId);
        url = url.replace('{target}', deviceTag ? `/${deviceTag}` : '');

        if (url.includes('?')) {
            url = url + "&returnUrl=" + encodeURIComponent(window.location.href);
        } else {
            url = url + "?returnUrl=" + encodeURIComponent(window.location.href);
        }

        window.location.href = url;
    }
}
